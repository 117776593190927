<template>
  <div class="Index">
    <div class="banner">

    </div>
    <div class="hezuo pd40">
      <div class="container">
        <div class="headTit">
          <h3>ZHUXUE <span>Highlight</span></h3>
        </div>
        <el-row :gutter="24">
          <el-col :span="8"
                  v-for="(item,index) in list"
                  :key="index">
            <div class="hezuoLis">
              <div class="imgbox">
                <img :src="item.thumb"
                     alt="">
              </div>
              <div class="text">
                <h3>{{item.en_name}}</h3>
                <p>{{item.desc}}</p>
                <router-link :to="'/school-detail/'+item.id">more</router-link>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination v-if="showpg"
                       background
                       layout="prev, pager, next"
                       @next-click="onNextPage"
                       @prev-click="onPrevPage"
                       @current-change="onCurrentChange"
                       :page-size="pageSize"
                       :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from "vue";
import axiosApi from "../api/api.js";
export default {
  components: {
  },
  data () {
    return {
    }
  },
  setup () {
    const list = ref()
    const total = ref(2)
    const pageSize = ref(12)
    const showpg = ref(false)
    const onCurrentChange = (val) => {
      axiosApi(
        'university/recommend?page=' + val, {}, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onNextPage = (val) => {
      axiosApi(
        'university/recommend?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      axiosApi(
        'university/recommend?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }

    return {
      onCurrentChange,
      onNextPage,
      onPrevPage,
      list,
      total,
      pageSize,
      showpg
    }
  },
  beforeMount () {
    axiosApi(
      'university/recommend', {}, 'get'
    ).then((res) => {
      console.log(res)
      this.showpg = true
      this.list = res.data.data
      this.total = res.data.total
      this.pageSize = res.data.per_page
    })
  },
  methods: {


  },
  watch: {
    curPage () {

    }
  },

};
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner5.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.hezuo {
  .infinite-list-item {
    padding: 10px;
    color: #666;
    display: flex;
    .imgbox {
      width: 150px;
    }
    .text {
      width: calc(100% - 150px);
      padding-left: 20px;
      h3 {
        color: #212121;
        line-height: 28px;
        font-size: 16px;
      }
      p {
        line-height: 1.7;
        font-size: 14px;
      }
    }
  }
  .hezuoLis {
    margin-bottom: 20px;
    .imgbox img {
      height: 230px;
      width: 385px;
    }
    .text {
      border: 1px solid #dedede;
      border-top: none;
      padding: 20px 10px;
      h3 {
        margin-bottom: 20px;
        color: #333;
        font-size: 16px;
      }
      p {
        height: 80px;
        overflow: hidden;
        font-size: 14px;
        color: #454545;
      }
      a {
        margin-top: 10px;
        border: 1px solid #0c387930;
        display: inline-block;
        padding: 3px 10px;
        transition: ease 0.5s;
        &:hover {
          background-color: #0c3879;
          color: #fff;
        }
      }
    }
  }
}
</style>
